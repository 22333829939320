import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Navigation from "./components/Navbar";
import Home from "./pages/Home";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Projects from "./pages/Projects";
import BlueVisionary from "./components/Projects/BlueVisionary";
import ImageRecognition from "./components/Projects/ImageRecognition";
import IngreRecipeGen from "./components/Projects/IngreRecipeGen";
import DeepLearning from "./components/Projects/DeepLearning";
import MultiAgent from "./components/Projects/MultiAgent";
import VolunUiDesign from "./components/Projects/VolunUiDesign";
import ResearchVideo from "./components/Projects/ResearchVideo";
import CsProject from "./components/Projects/CsProject";
import ScrollToTop from "./components/ScrollToTop";
import SteamReviewAnalysis from "./components/Projects/SteamReviewAnalysis";

// import Footer from "./components/Footer";

const App = () => (
  <Router>
    <div>
      <Navigation />
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/projects/bluevisionary" element={<BlueVisionary />} />
        <Route
          path="/projects/imageRecognition"
          element={<ImageRecognition />}
        />
        <Route path="/projects/ingreRecipeGen" element={<IngreRecipeGen />} />
        <Route path="/projects/deepLearning" element={<DeepLearning />} />
        <Route path="/projects/multiAgent" element={<MultiAgent />} />
        <Route path="/projects/volunUiDesign" element={<VolunUiDesign />} />
        <Route path="/projects/researchVideo" element={<ResearchVideo />} />
        <Route path="/projects/csProject" element={<CsProject />} />
        <Route
          path="/projects/steamReviewAnalysis"
          element={<SteamReviewAnalysis />}
        />
      </Routes>
      {/* <Footer /> */}
    </div>
  </Router>
);

export default App;
