import React from "react";
import "../../styles/ProjectDetails.scss";
// import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
// import { Link } from "react-router-dom";

const SteamReviewAnalysis = () => {
  return (
    <div className="project-details-container">
      {/* Banner Section */}
      <div className="project-image">
        <img
          src={"/assets/projects/SteamReviewAnalysis/Landing.png"}
          alt={"SteamReviewAnalysis"}
          className="project-image"
        />
      </div>

      {/* Overview Section */}
      <div className="section model-training">
        <h2>Overview</h2>
        <p>
          This project aims to analyze and cluster multilingual Steam game
          reviews using multilingual BERT (mBERT) for word embeddings and
          unsupervised clustering techniques. The goal is to identify patterns
          in language distribution, sentiment polarity, and player engagement
          styles across different game reviews.
        </p>
        <p>
          🔍 Key Objectives
          <li>
            Text Processing & Embedding: Preprocess Steam reviews in multiple
            languages.
          </li>
          <li>
            Use mBERT (Hugging Face Transformers) to generate word embeddings
            for clustering.
          </li>
          <li>
            Unsupervised Clustering: Apply clustering algorithms to group
            reviews based on linguistic similarity and sentiment patterns.
          </li>
          <li>
            Identify common themes across different clusters and analyze the
            distribution of languages in each group.
          </li>
        </p>
      </div>

      {/* Github Link Section */}
      <div className="section github-link">
        <h2>Github Link</h2>
        <ul>
          <li>
            <strong>
              GitHub{" "}
              <i className="fab fa-github" style={{ marginRight: "5px" }}></i>:{" "}
            </strong>{" "}
            <a
              href="https://github.com/YiJieNG/NLP---SteamReviewAnalysis"
              target="_blank"
              rel="noopener noreferrer"
            >
              github.com/YiJieNG/NLP---SteamReviewAnalysis
            </a>
          </li>
        </ul>
      </div>

      {/* Dataset Link Section */}
      <div className="section dataset-link">
        <h2>Dataset Link</h2>
        <ul>
          <li>
            <strong>Dataset : </strong>{" "}
            <a
              href="https://www.kaggle.com/datasets/lucaspoo/steam-reviews-international/data"
              target="_blank"
              rel="noopener noreferrer"
            >
              www.kaggle.com/datasets/lucaspoo/steam-reviews-international/data
            </a>
          </li>
        </ul>
      </div>

      {/* Challenges */}
      <div className="section challenges">
        <h2>Challenges</h2>
        <p>
          It took significant time to run the feature extraction using mBERT
          transformer since we are using a large dataset. To address this,
          several strategies are applied to reduce the running time:
          <li>
            Resource: Switch from solely depends on CPU to CUDA core using RTX
            3060
          </li>
          <li>Optimisation technique applied: Batching</li>
        </p>
        <p>
          As a result, the running time has decreased significantly{" "}
          <strong>from more than 7 hours to 1.5 hours.</strong>
        </p>
      </div>

      {/*Data Loading Section */}
      <div className="section model-training-config">
        <h2>Dataset preview</h2>
        <img
          src={"/assets/projects/SteamReviewAnalysis/DataLoading.png"}
          alt={"Data Statistic"}
          className="desc-image"
        />
      </div>

      {/*Data Preprocessing Section */}
      <div className="section data-preprocessing">
        <h2>Data Preprocessing</h2>
        <li>Convert timestamps to datetime objects.</li>
        <li>Extract year from timestamp.</li>
        <li>
          Clean review text by lowercasing, remove urls, remove html tags,
          remove special characters but keep spaces between words and remove
          extra white space.
        </li>
        <li>Drop rows with empty reviews after cleaning.</li>
        <li>Remove duplicates based on review_id.</li>
        <li>Convert voted_up to a sentiment label</li>
      </div>

      {/*Evaluation Section */}
      <div className="section data-preprocessing">
        <h2>Evaluation</h2>
        <p>
          <strong>Clustering Result:</strong>
        </p>
        <p>
          <img
            src={"/assets/projects/SteamReviewAnalysis/Result.png"}
            alt={"Train and Valid loss for each model"}
            className="desc-image"
          />
          <img
            src={"/assets/projects/SteamReviewAnalysis/Result (2).png"}
            alt={"Train and Valid loss for each model"}
            className="desc-image"
          />
        </p>
      </div>

      {/*Data Preprocessing Section */}
      <div className="section data-preprocessing">
        <h2>Data Preprocessing</h2>
        <li>Convert timestamps to datetime objects.</li>
        <li>Extract year from timestamp.</li>
        <li>
          Clean review text by lowercasing, remove urls, remove html tags,
          remove special characters but keep spaces between words and remove
          extra white space.
        </li>
        <li>Drop rows with empty reviews after cleaning.</li>
        <li>Remove duplicates based on review_id.</li>
        <li>Convert voted_up to a sentiment label</li>
      </div>

      {/*Data Preprocessing Section */}
      <div className="section data-justification">
        {" "}
        <h2>Cluster Analysis</h2>
        <div style={{ padding: "2rem 0 1rem" }}>
          <h4>Cluster 0</h4>
          <p>
            <strong>Size:</strong> 172,964 reviews
          </p>
          <p>
            <strong>Languages:</strong> Dominated by Chinese, English, and
            Russian content but also containing a significant amount of Korean
            and Brazilian.
          </p>
          <p>
            <strong>Sentiment:</strong> <strong>76% Positive (131,656)</strong>{" "}
            vs. <strong>24% Negative (41,308)</strong>
          </p>
          <p>
            <strong>Possible Interpretation:</strong>
          </p>
          <ul>
            <li>
              This cluster contains a <strong>diverse mix of languages</strong>,
              notably Asian (Chinese, Japanese, Korean) and some European
              (Russian, Turkish).
            </li>
            <li>
              Many reviews <strong>go beyond simple praise or criticism</strong>
              —they contain detailed discussions on gameplay, mechanics, and
              user frustrations.
            </li>
            <li>
              The large number of <strong>positive reviews (76%)</strong> may
              indicate that this cluster contains invested players who provide
              constructive feedback rather than outright complaints.
            </li>
            <li>
              Reviews may be more{" "}
              <strong>nuanced and regionally specific</strong>, highlighting
              different player expectations across cultures.
            </li>
          </ul>
        </div>
        <div style={{ padding: "2rem 0 1rem" }}>
          <h3>Cluster 1</h3>
          <p>
            <strong>Size:</strong> 34,594 reviews (smallest cluster)
          </p>
          <p>
            <strong>Languages:</strong> Dominated by English.
          </p>
          <p>
            <strong>Sentiment:</strong> <strong>71% Positive (24,532)</strong>{" "}
            vs. <strong>29% Negative (10,062)</strong>
          </p>
          <p>
            <strong>Possible Interpretation:</strong>
          </p>
          <ul>
            <li>
              A <strong>smaller, more critical cluster</strong> compared to the
              others.
            </li>
            <li>
              A lot of <strong>English-speaking reviews</strong>, but more
              balance in sentiment (71% positive, 29% negative) compared to
              Cluster 0.
            </li>
            <li>
              Reviews are <strong>longer and more detailed</strong>, with
              players weighing pros and cons rather than leaving short
              reactions.
            </li>
            <li>
              Likely represents players who are{" "}
              <strong>more involved in the gaming community</strong>, including
              those actively reporting bugs or expecting high standards.
            </li>
          </ul>
        </div>
        <div style={{ padding: "2rem 0 1rem" }}>
          <h3>Cluster 2</h3>
          <p>
            <strong>Size:</strong> 159,091 reviews
          </p>
          <p>
            <strong>Languages:</strong> Mostly English (137,789), with smaller
            amounts of Russian (3,945) and German (3,773).
          </p>
          <p>
            <strong>Sentiment:</strong> <strong>78% Positive (123,450)</strong>{" "}
            vs. <strong>22% Negative (35,641)</strong>
          </p>
          <p>
            <strong>Possible Interpretation:</strong>
          </p>
          <ul>
            <li>
              This cluster is{" "}
              <strong>dominated by English-language reviews</strong>, possibly
              casual or short-form reviews.
            </li>
            <li>
              Reviews tend to be <strong>short and direct</strong> (e.g., “meh,”
              “Fun game”).
            </li>
            <li>
              <strong>High positivity (78%)</strong> suggests this may contain
              more casual players who either like a game or don’t engage deeply.
            </li>
            <li>
              The presence of <strong>idle/survival game reviews</strong>{" "}
              suggests this cluster may consist of casual gamers who leave brief
              but positive feedback.
            </li>
          </ul>
        </div>
      </div>

      {/* Footer Section */}
      <div className="footer">
        <p>&copy; Ng Yi Jie</p>
      </div>
    </div>
  );
};

export default SteamReviewAnalysis;
