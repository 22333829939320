import React from "react";
import { Link } from "react-router-dom";
import "../styles/Projects.scss";

const projects = [
  {
    id: 1,
    title: "BlueVisionary",
    description: (
      <>
        A platform focused on marine conservation and tracking ocean cleanup
        impact. This project represents a <strong>fully developed</strong> web
        application, incorporating <strong>university-acquired skills</strong>.
      </>
    ),
    link: "/projects/bluevisionary",
    image: "/assets/projects/BlueVisionary/LandingPage.png",
  },
  {
    id: 2,
    title: "Image Recognition for Plastic Items",
    description: (
      <>
        Image recognition using <strong>YOLOv9c</strong> to identify the number
        of plastic items in an image and{" "}
        <strong>integrate with Bluevisionary</strong> application.
      </>
    ),
    link: "/projects/imageRecognition",
    image: "/assets/projects/ImageRecognition/LandingImageRecognition.png",
  },
  {
    id: 9,
    title: "NLP - Steam Review Analysis",
    description: (
      <>
        This project aims to analyze and cluster multilingual Steam game reviews
        using <strong>multilingual BERT (mBERT)</strong> for word embeddings and
        unsupervised clustering techniques.
      </>
    ),
    link: "/projects/steamReviewAnalysis",
    image: "/assets/projects/SteamReviewAnalysis/Landing.png",
  },
  {
    id: 3,
    title: "NLP - Ingredient Recipe Generator",
    description: (
      <>
        A <strong>Sequence-to-Sequence modelling</strong> task, mapping
        ingredients to a recipe with <strong>RNN techniques</strong> included
        such as attention mechanism.
      </>
    ),
    link: "/projects/ingreRecipeGen",
    image: "/assets/projects/IngreRecipeGen/Landing.png",
  },
  {
    id: 4,
    title: "Deep Learning Projects",
    description: (
      <>
        Focused on creating high-performing deep learning models across various
        architectures, including{" "}
        <strong>
          Deep Neural Networks (DNN), Convolutional Neural Networks (CNN) and
          Recurrent Neural Networks (RNN)
        </strong>
        .
      </>
    ),
    link: "/projects/deepLearning",
    image: "/assets/projects/DeepLearning/Landing.png",
  },
  {
    id: 5,
    title: "Reinforment Learning: Multi-agent collaboration",
    description: (
      <>
        Project for <strong>multiple agents</strong> in a square grid world to
        <strong> learn a simple transport task</strong> that requires
        coordination between the agents using
        <strong> Deep-Q</strong> to let the agents learn this task.{" "}
      </>
    ),
    link: "/projects/multiAgent",
    image: "/assets/projects/MultiAgent/Landing.png",
  },
  {
    id: 6,
    title: "Usability Design: Volunteering App",
    description: (
      <>
        {" "}
        <strong>Design and deliver</strong> a prototype of a{" "}
        <strong>mobile application</strong>
        that helps users find long-term <strong>
          volunteer positions
        </strong>{" "}
        that suit their needs. Prototypes designed include:{" "}
        <strong>
          Low-Fidelity Prototype and High-Fidelity Prototype using Figma.
        </strong>
      </>
    ),
    link: "/projects/volunUiDesign",
    image: "/assets/projects/VolunUiDesign/Landing.png",
  },
  {
    id: 7,
    title: "Investment Video for a Research Paper",
    description: (
      <>
        A <strong>90 second video </strong>that will be sent to a number of
        <strong> investment companies</strong> seeking opportunities. The only
        goal of the video is to <strong>stimulate interest</strong> in the
        organizations to find out more about the research.
      </>
    ),
    link: "/projects/researchVideo",
    image: "/assets/projects/ResearchVideo/Landing.png",
  },
  {
    id: 8,
    title: "Computer Science Projects",
    description: (
      <>
        Projects completed during <strong>Bachelor of Computer Science</strong>{" "}
        at Monash University focused on{" "}
        <strong>finding optimal solutions</strong> using various strategies and
        algorithms including Dijkstra's algorithm, dynamic programming, and A*
        (Ukennon). Additionally, <strong>smaller projects</strong> such as the
        Space Invader Game and mobile design prototypes were also undertaken.
      </>
    ),
    link: "/projects/csProject",
    image: "/assets/projects/ComputerScience/Landing.png",
  },
];

const Projects = () => {
  return (
    <div className="projects-container">
      <h1>My Projects</h1>
      <div className="projects-list">
        {projects.map((project) => (
          <Link
            to={project.link}
            key={project.id}
            className="project-card"
            style={{ textDecoration: "none" }}
          >
            <h2 style={{ paddingBottom: "5px" }}>{project.title}</h2>
            <img
              src={project.image}
              alt={project.title}
              className="project-image"
            />

            <p>{project.description}</p>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default Projects;
